import '../styles/blog.css'

import * as React from 'react'

import { WSASERVICE_NOT_FOUND } from 'constants'
import { navigate } from 'gatsby'

const ReadmeBump = () => {
  React.useEffect(() => {
    setTimeout(() => {
      window.location.href = 'https://docs.google.com/presentation/d/1TB70SrEEA1e0om-EICoZMvJ5ryquXIbJtw_bdl-udQM/edit#slide=id.p'
      // navigate('blog/2020-05-25-personal-readme', { replace: true })
    }, 100)
  }, [])
  return <span></span>
}
export default ReadmeBump
